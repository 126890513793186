<template>
  <div class="button-item">
    <v-btn
      class="main-button"
      width="100%"
      color="#F5EDE2"
      :disabled="disabled"
      :class="{ disabled }"
      @click="$emit(emitName)"
    >
      <div class="main-button__container">
        <i :class="['main-button__append-icon', icon]"></i>
        <span class="main-button__name">{{ text }}</span>
        <v-icon class="main-button__prepend-icon">mdi-chevron-right</v-icon>
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "MenuButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    emitName: {
      type: String,
      default: "click",
    },
    icon: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.button-item {
  margin-bottom: 25px;
  .main-button {
    padding-bottom: 8px;
    padding-top: 8px;
    text-transform: none;
    height: 75px;
    &__container {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__append-icon {
      font-size: 18px;
      margin-right: 10px;
    }
    &__name {
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 150%;
      color: #1d1d1b;
      margin-right: 8px;
    }
    &__prepend-icon {
      margin-left: auto;
    }
  }
  .disabled {
    &.v-btn {
      background-color: rgb(245, 237, 226) !important;
    }
    i,
    .main-button__name {
      color: rgba(29, 29, 27, 0.5) !important;
    }
  }
}
</style>
