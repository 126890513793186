<template>
  <div class="footer">
    <div class="logo">
      <img class="logo__img" src="@/assets/img/smb-footer-logo.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SmbFooter",
};
</script>

<style scoped lang="scss">
.footer {
  margin-top: auto;
  padding: 22px;
  display: flex;
  justify-content: flex-end;
}
</style>
