var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smb-client-wrapper"},[(_vm.isLoading)?_c('Loader'):[_c('SmbTitles',{attrs:{"title":`Welcome ${_vm.isNewUser ? '' : 'back'} ${
        _vm.getCurrentSmbCustomer.personal_name
      }!`,"is-show-title":"","alignCenter":true}}),_c('div',{staticClass:"smb-menu-button-wrapper"},[((_vm.isNewUser && _vm.isShowTasks) || _vm.isNeedToConnectSocialNetworks)?_c('div',{staticClass:"steps-wrapper"},[(_vm.isNewUser && _vm.isShowTasks)?_c('div',{staticClass:"new-user-steps"},[_c('span',{staticClass:"fake-checkbox"},[(false)?_c('img',{attrs:{"src":require("@/assets/img/icons/fake-check-mark.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"new-user-steps__title"},[_vm._v(" Update your brand colors/logo/etc ")])]):_vm._e(),(_vm.isNeedToConnectSocialNetworks)?_c('div',{staticClass:"new-user-steps",on:{"click":_vm.connectSocialPlatformsHandler}},[_c('span',{staticClass:"fake-checkbox"},[(false)?_c('img',{attrs:{"src":require("@/assets/img/icons/fake-check-mark.svg"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"new-user-steps__title"},[_vm._v(" Connect your social accounts ")])]):_vm._e()]):_vm._e(),_c('v-btn',{staticClass:"review-button",attrs:{"width":"100%","color":"#F5EDE2","loading":_vm.isLoadingPendingBtn},on:{"click":function($event){return _vm.approveContentHandler()}}},[_c('div',{staticClass:"review-button__container"},[_c('i',{staticClass:"review-button__append-icon icon-checkbox-icon",class:{
              'pending-state': _vm.getAmountPostsToReview,
              'new-user': _vm.isNewUser,
            }}),(!_vm.isNewUser)?[(_vm.getAmountPostsToReview)?_c('span',{staticClass:"review-button__name"},[_vm._v("Pending items for review")]):_c('span',{staticClass:"review-button__name"},[_vm._v("No items for review")])]:[_c('span',{staticClass:"review-button__name"},[_vm._v("Our AI is creating customized content just for you")])],(_vm.getAmountPostsToReview)?_c('span',{staticClass:"review-button__amount"},[_vm._v(_vm._s(_vm.getAmountPostsToReview))]):_vm._e(),(_vm.getAmountPostsToReview)?_c('v-icon',{staticClass:"review-button__prepend-icon"},[_vm._v("mdi-chevron-right")]):_vm._e()],2),(!_vm.getAmountPostsToReview && !_vm.isNewUser)?_c('div',{staticClass:"review-button__message"},[_vm._v(" Generate content or wait for next week’s recommendations ")]):_vm._e(),(_vm.isNewUser)?_c('div',{staticClass:"review-button__message"},[_vm._v(" You'll receive a message once it’s ready ")]):_vm._e()]),_vm._l((_vm.menuButtons),function(button){return _c('SmbMenuButton',{key:button.id,attrs:{"text":button.name,"disabled":button.id === _vm.menuButtonsIds.CONTENT_CALENDAR &&
          !_vm.getContentCalendarAmount,"icon":button.icon,"emit-name":button.emitName},on:{"approveContent":_vm.approveContentHandler,"generateContent":_vm.generateContentHandler,"contentCalendar":_vm.contentCalendarHandler,"settings":_vm.settingsHandler}})})],2),_c('SmbFooter')],_c('ModalDialog',{attrs:{"isShow":_vm.connectIgDialog.isShow,"customClass":"connect-facebook","size":_vm.connectIgDialog.size,"footerButtons":_vm.connectIgDialog.footerButtons},on:{"success":_vm.redirectToConnectSocialsService,"cancel":function($event){_vm.connectIgDialog.isShow = false},"close":function($event){_vm.connectIgDialog.isShow = false}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"connect-info"},[_c('div',{staticClass:"connect-info__title"},[_vm._v("Authenticate with Facebook")]),_c('div',{staticClass:"connect-info__text"},[_c('h5',[_vm._v("Why do I have to authenticate with Facebook?")]),_c('p',{staticClass:"mb20"},[_vm._v(" Facebook requires "),_c('span',{staticClass:"underline"},[_vm._v("Instagram business accounts to be connected to a Facebook Page")]),_vm._v(". For us to be able to post on your behalf, we need to authenticate through Facebook. ")]),_c('h5',[_vm._v("Want to post only to Instagram?")]),_c('p',{staticClass:"mb20"},[_vm._v(" No problem, authenticate with Facebook, and select only your Instagram account from the Facebook selection menu. ")]),_c('h5',[_vm._v("So what’s next?")]),_c('p',{staticClass:"mb20"},[_vm._v(" Click the button below, sign in to the Facebook profile that has Admin permissions for the Facebook Page connected with your Instagram account. ")]),_c('h5',[_vm._v("Need help?")]),_c('p',{staticClass:"mb20"},[_vm._v(" Simply send us a message to support@social-wonder.com and we’ll schedule a short Zoom (usually takes less than 3 minutes) to help you set up everything. ")])])])]},proxy:true}])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }