<template>
  <div class="smb-client-wrapper">
    <Loader v-if="isLoading" />
    <template v-else>
      <SmbTitles
        :title="`Welcome ${isNewUser ? '' : 'back'} ${
          getCurrentSmbCustomer.personal_name
        }!`"
        is-show-title
        :alignCenter="true"
      />
      <div class="smb-menu-button-wrapper">
        <div
          v-if="(isNewUser && isShowTasks) || isNeedToConnectSocialNetworks"
          class="steps-wrapper"
        >
          <div v-if="isNewUser && isShowTasks" class="new-user-steps">
            <span class="fake-checkbox">
              <img
                v-if="false"
                src="@/assets/img/icons/fake-check-mark.svg"
                alt=""
              />
            </span>
            <div class="new-user-steps__title">
              Update your brand colors/logo/etc
            </div>
          </div>
          <div
            v-if="isNeedToConnectSocialNetworks"
            class="new-user-steps"
            @click="connectSocialPlatformsHandler"
          >
            <span class="fake-checkbox">
              <img
                v-if="false"
                src="@/assets/img/icons/fake-check-mark.svg"
                alt=""
              />
            </span>
            <div class="new-user-steps__title">
              Connect your social accounts
            </div>
          </div>
        </div>

        <v-btn
          class="review-button"
          width="100%"
          color="#F5EDE2"
          :loading="isLoadingPendingBtn"
          @click="approveContentHandler()"
        >
          <div class="review-button__container">
            <i
              class="review-button__append-icon icon-checkbox-icon"
              :class="{
                'pending-state': getAmountPostsToReview,
                'new-user': isNewUser,
              }"
            ></i>
            <template v-if="!isNewUser">
              <span class="review-button__name" v-if="getAmountPostsToReview"
                >Pending items for review</span
              >
              <span class="review-button__name" v-else
                >No items for review</span
              >
            </template>
            <template v-else>
              <span class="review-button__name"
                >Our AI is creating customized content just for you</span
              >
            </template>
            <span class="review-button__amount" v-if="getAmountPostsToReview">{{
              getAmountPostsToReview
            }}</span>
            <v-icon
              class="review-button__prepend-icon"
              v-if="getAmountPostsToReview"
              >mdi-chevron-right</v-icon
            >
          </div>
          <div
            class="review-button__message"
            v-if="!getAmountPostsToReview && !isNewUser"
          >
            Generate content or wait for next week’s recommendations
          </div>
          <div class="review-button__message" v-if="isNewUser">
            You'll receive a message once it’s ready
          </div>
        </v-btn>
        <SmbMenuButton
          v-for="button in menuButtons"
          :text="button.name"
          :key="button.id"
          :disabled="
            button.id === menuButtonsIds.CONTENT_CALENDAR &&
            !getContentCalendarAmount
          "
          :icon="button.icon"
          :emit-name="button.emitName"
          @approveContent="approveContentHandler"
          @generateContent="generateContentHandler"
          @contentCalendar="contentCalendarHandler"
          @settings="settingsHandler"
        />
      </div>
      <SmbFooter />
    </template>
    <ModalDialog
      :isShow="connectIgDialog.isShow"
      customClass="connect-facebook"
      :size="connectIgDialog.size"
      :footerButtons="connectIgDialog.footerButtons"
      @success="redirectToConnectSocialsService"
      @cancel="connectIgDialog.isShow = false"
      @close="connectIgDialog.isShow = false"
    >
      <template v-slot:description>
        <div class="connect-info">
          <div class="connect-info__title">Authenticate with Facebook</div>
          <div class="connect-info__text">
            <h5>Why do I have to authenticate with Facebook?</h5>
            <p class="mb20">
              Facebook requires
              <span class="underline"
                >Instagram business accounts to be connected to a Facebook
                Page</span
              >. For us to be able to post on your behalf, we need to
              authenticate through Facebook.
            </p>
            <h5>Want to post only to Instagram?</h5>
            <p class="mb20">
              No problem, authenticate with Facebook, and select only your
              Instagram account from the Facebook selection menu.
            </p>
            <h5>So what’s next?</h5>
            <p class="mb20">
              Click the button below, sign in to the Facebook profile that has
              Admin permissions for the Facebook Page connected with your
              Instagram account.
            </p>
            <h5>Need help?</h5>
            <p class="mb20">
              Simply send us a message to support@social-wonder.com and we’ll
              schedule a short Zoom (usually takes less than 3 minutes) to help
              you set up everything.
            </p>
          </div>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { MENU_BUTTONS, MENU_BUTTONS_IDS } from "@/constants/smb-client";
import smbMethods from "@/mixins/smbMethods";

import SmbMenuButton from "@/components/smb-client/SmbMenuButton";
import SmbTitles from "@/components/smb-client/SmbTitles";
import SmbFooter from "@/components/smb-client/SmbFooter";
import Loader from "@/components/global/Loader";
import ModalDialog from "@/components/global/ModalDialog.vue";

export default {
  name: "SmbClientMainPage",
  mixins: [smbMethods],
  data: () => ({
    menuButtons: MENU_BUTTONS,
    menuButtonsIds: MENU_BUTTONS_IDS,
    amountArchivePosts: null,
    isLoading: false,
    isLoadingPendingBtn: false,
    isDisablePendingBtn: false,
    connectIgDialog: {
      isShow: false,
      size: 433,
      footerButtons: [
        {
          name: "Cancel",
          emitName: "cancel",
          btnClass: "border",
        },
        {
          name: "Authenticate with Facebook",
          emitName: "success",
          btnClass: "black",
        },
      ],
    },
  }),
  components: {
    ModalDialog,
    Loader,
    SmbMenuButton,
    SmbTitles,
    SmbFooter,
  },
  computed: {
    ...mapGetters("smbClientSelection", ["getCurrentSmbCustomer"]),
    ...mapGetters("smbPost", [
      "getAmountPostsToReview",
      "getContentCalendarAmount",
    ]),
  },
  async created() {
    this.isLoading = !this.$isMobile();
    this.isLoadingPendingBtn = this.$isMobile();
    this.isDisablePendingBtn = this.$isMobile();
    await this.amountPosts();
    if (!this.$isMobile()) {
      this.getAmountPostsToReview
        ? await this.$router.push({
            name: "smb-feedback",
            params: { id: this.getCurrentSmbCustomer.id },
          })
        : await this.$router.push({
            name: "generate-content",
            params: { id: this.getCurrentSmbCustomer.id },
          });
    }
    this.isDisablePendingBtn = false;
    this.isLoadingPendingBtn = false;
    this.isLoading = false;
  },
  methods: {
    ...mapActions("smbPost", ["fetchAmountsPostsToReview"]),
    async amountPosts() {
      try {
        await this.fetchAmountsPostsToReview({
          customerId: this.getCurrentSmbCustomer.id,
        });
      } catch (e) {
        console.log(e);
      }
    },
    connectSocialPlatformsHandler() {
      if (!this.getCurrentSmbCustomer.fb_page_link) {
        this.redirectToConnectSocialsService();
      } else {
        this.connectIgDialog.isShow = true;
      }
    },
    redirectToConnectSocialsService() {
      if (this.getCurrentSmbCustomer.saas_redirect_link_list.length > 1) {
        window.open(
          this.getCurrentSmbCustomer.saas_redirect_link_list[0],
          "_blank"
        );
      } else {
        window.open(this.getCurrentSmbCustomer.saas_redirect_link, "_blank");
      }
    },
    generateContentHandler() {
      this.$router.push({
        name: "generate-content",
        params: { isNeedLoadRecommendation: true },
      });
    },
    approveContentHandler() {
      if (!this.isNewUser) {
        this.$router.push({
          name: "smb-feedback",
          params: { id: this.getCurrentSmbCustomer.id },
        });
      }
    },
    settingsHandler() {
      this.$router.push({
        name: "profile-settings",
      });
    },
    contentCalendarHandler() {
      if (this.getContentCalendarAmount) {
        this.$router.push({
          name: "smb-content-calendar",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.smb-client-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  .smb-menu-button-wrapper {
    padding: 20px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 0 25px;
    width: 100%;
    @media screen and (min-width: 600px) {
      margin: 0 auto;
      max-width: 500px;
    }
  }
  .steps-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    .new-user-steps {
      border: 1px solid #671539;
      border-radius: 5px;
      padding: 10px 14px;
      display: inline-flex;
      width: 100%;
      align-items: center;
      background: #f5ede2;
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }
      .fake-checkbox {
        width: 13px;
        height: 13px;
        flex-shrink: 0;
        border: 1px solid #671539;
        border-radius: 3px;
        background: #ffffff;
        margin-right: 15px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      &__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 130%;
        color: #671539;
      }
    }
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
  .review-button {
    margin-bottom: 25px;
    min-height: 110px;
    display: flex;
    align-items: center;
    text-transform: none;
    white-space: normal;
    text-align: left;
    ::v-deep {
      .v-btn__content {
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-start;
        letter-spacing: 0;
        text-indent: 0;
        width: 100%;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__append-icon {
      font-size: 18px;
      margin-right: 10px;
      &.icon-checkbox-icon {
        position: relative;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        flex-shrink: 0;
        &:after {
          content: "";
          width: 13px;
          height: 13px;
          background: #fff;
          position: absolute;
          left: 2px;
          right: 0;
          top: 2px;
          bottom: 0;
          border-radius: 3px;
        }
        &:before {
          position: relative;
          z-index: 1;
        }
        &.pending-state {
          position: relative;
          &:before {
            z-index: 0;
          }
        }
      }
    }
    &__name {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #1d1d1b;
      margin-right: 8px;
      letter-spacing: 0;
      @media screen and (max-width: 400px) {
        letter-spacing: -1px;
        font-size: 19px;
      }
    }
    &__prepend-icon {
      margin-left: auto;
    }
    &__amount {
      width: 18px;
      height: 18px;
      display: inline-flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      background: #671539;
      color: #fff;
      font-weight: 700;
      font-size: 11px;
      margin-right: 5px;
      border-radius: 50%;
    }
    &__message {
      font-weight: 400;
      font-size: 10px;
      line-height: 168.69%;
      color: #671539;
      padding-left: 30px;
      @media screen and (max-width: 400px) {
        padding-left: 0;
      }
    }
  }
}
.connect-info {
  display: flex;
  flex-direction: column;
  text-align: left;

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #1f2325;
    text-align: left;
    padding: 14px 20px 11px 38px;
  }

  &__text {
    background: #f5ede2;
    padding: 17px 22px 10px 36px;

    h5 {
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 5px;
      color: #000;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #000;
    }

    .mb20 {
      margin-bottom: 20px;
    }

    .underline {
      text-decoration: underline;
    }
  }
}
</style>
